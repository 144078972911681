<script setup lang="ts"></script>

<template>
  <div class="text-center">
    <h1 class="wrap-text-glow text-[80px] font-[900] leading-tight text-white">
      Your Lenkie <br />
      Recap
    </h1>
    <div class="mx-auto my-6 w-[83px] border-[2px] border-[#5775BC]"></div>
    <p class="text-[42px] font-[900] text-[#F5A623]">2024</p>
  </div>
</template>
