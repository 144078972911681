<script setup lang="ts"></script>

<template>
  <div class="text-center">
    <h1
      class="wrap-text-glow max-w-[540px] text-[34px] font-[900] leading-snug text-white"
    >
      You made moves, and achieved goals, and we were here to back you every
      step of the way. <br />
      Let’s look back at your incredible financial journey with “Lenkie”.
    </h1>
  </div>
</template>
