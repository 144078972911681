<script setup lang="ts">
import WrapSlider from './WrapSlider.vue'

const { $listen } = useNuxtApp()

const openWraps = ref(false)

$listen('open:lenkie-wraps', () => {
  openWraps.value = true
})

const profileStore = useProfileStore()
const lenkieWrap = computed(() => {
  const wrapData = profileStore.data.organisation?.lenkie_wrapped
  if (!wrapData) return null

  return wrapData
})
</script>

<template>
  <BaseModal
    v-if="!!lenkieWrap && openWraps"
    size="2xl"
    show
    padding="p-0"
    :show-heading="false"
    bg-class="bg-none"
    :show-divider-between-heading-and-content="false"
    @close-modal="openWraps = false"
  >
    <WrapSlider :lenkie-wrap="lenkieWrap" />
  </BaseModal>
</template>
