<script setup lang="ts">
import { watchImmediate } from '@vueuse/core'
import { PhCaretLeft, PhCaretRight } from '@phosphor-icons/vue'
import type { CarouselApi } from '~/components/ui/carousel'
import TheIcon from '@/components/shared/the-icon.vue'

import type { IOrganisation } from '~/services'

defineProps<{
  lenkieWrap: IOrganisation['lenkie_wrapped']
}>()

const emblaMainApi = ref<CarouselApi | null>(null)
const currentIndex = ref(0)
const bgRotate = ref(0)
const bgTranslate = ref(0)

watchImmediate(currentIndex, () => {
  bgRotate.value = Math.floor(Math.random() * 100)
  bgTranslate.value = Math.floor(Math.random() * 200)
})

function setApi(val: CarouselApi) {
  emblaMainApi.value = val
}

function jumpToIndex(index: number) {
  emblaMainApi.value?.scrollTo(index)
  bgRotate.value = Math.floor(Math.random() * 100)
  bgTranslate.value = Math.floor(Math.random() * 200)
}

const scrollListener: (() => void) | null = null
onBeforeUnmount(() => {
  if (scrollListener) {
    emblaMainApi.value?.off('scroll', scrollListener)
  }
})

onMounted(() => {
  emblaMainApi.value?.on('scroll', function () {
    currentIndex.value = emblaMainApi.value?.selectedScrollSnap() || 0
  })
})
</script>

<template>
  <div>
    <div class="flex w-full justify-center">
      <Carousel
        class="relative z-10 size-full overflow-hidden rounded-[45px] bg-primary"
        @init-api="setApi"
      >
        <div class="absolute left-4 top-1/2 z-50 -translate-y-1/2">
          <Button
            variant="secondary"
            size="icon"
            class="rounded-full"
            :disabled="currentIndex < 1"
            @click="jumpToIndex(currentIndex - 1)"
            ><PhCaretLeft
          /></Button>
        </div>
        <div class="absolute right-4 top-1/2 z-20 -translate-y-1/2">
          <Button
            variant="secondary"
            size="icon"
            class="rounded-full"
            :disabled="currentIndex === 5"
            @click="jumpToIndex(currentIndex + 1)"
            ><PhCaretRight
          /></Button>
        </div>
        <div class="absolute inset-0 -z-10 h-full w-full scale-[1.7]">
          <the-icon
            icon-name="wrap-bg"
            :style="{
              height: '100%',
              width: '100%',
              transform: `rotate(${bgRotate}deg)translate(${bgTranslate}px)`,
            }"
            class="ease-transition relative top-5"
          />
        </div>
        <div
          class="absolute top-0 z-50 flex w-full items-center justify-center"
        >
          <div
            class="flex w-full items-center justify-between gap-2 rounded-[1rem] bg-[#040E39] px-16 py-2"
          >
            <button
              v-for="(_, idx) in Array(6)"
              :key="idx"
              class="size-1 w-[84px] rounded-full"
              :class="[currentIndex === idx ? 'bg-[#F5A623]' : 'bg-[#D9D9D9]']"
              @click="jumpToIndex(idx)"
            ></button>
          </div>
        </div>
        <CarouselContent>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen1 />
          </CarouselItem>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen2 />
          </CarouselItem>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen3 :data="lenkieWrap" />
          </CarouselItem>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen4 :data="lenkieWrap" />
          </CarouselItem>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen5 :data="lenkieWrap" />
          </CarouselItem>
          <CarouselItem
            class="flex h-[724px] w-[724px] items-center justify-center"
          >
            <ModulesWrapScreensScreen6 />
          </CarouselItem>
        </CarouselContent>
      </Carousel>
    </div>
  </div>
</template>
