<template>
  <div
    :class="!isAccountingConnected ? 'lg:h-[580px]' : 'lg:h-[450px]'"
    class="mx-auto rounded-lg border bg-white px-4 py-5 lg:w-[853px] lg:px-8"
  >
    <div class="text-center text-base font-bold text-primary lg:text-xl">
      Upload a bill to start making payments
    </div>
    <div
      class="mt-6 flex items-center justify-center bg-[#F8F9FA] md:h-[335px]"
    >
      <!-- <div v-if="!isUploading" v-bind="getRootProps()">
          <input
            data-test-id="single-bill-upload-input"
            v-bind="getInputProps()"
          />
          <div onclick="open">
            <div class="mt-4 flex justify-center">
              <Button variant="outline" size="icon" class="rounded-lg">
                <img src="@/assets/svgs/upload.svg" alt="svg" />
              </Button>
            </div>
            <div class="relative mt-3 flex justify-center">
              <p class="cursor-pointer text-center text-sm leading-[20px]">
                <span class="font-semibold text-primary">Click to upload</span>
                <span class="ml-1 text-gray-600"
                  >or drag and drop <br />
                  PDF, PNG or JPG (max 5MB)</span
                >
              </p>
            </div>
          </div>
        </div> -->
      <div v-if="!isUploading" class="h-full w-full">
        <UploadsFileUploader
          class="h-full w-full bg-white hover:ring-2 hover:ring-slate-200"
          :max-size="5_000_000"
          accept="image/jpg,image/jpeg,image/png,application/pdf,.csv"
          :multiple="true"
          @files="onDrop"
          @rejections="handleRejections"
        />
        <!-- 
        <p v-for="(rej, idx) in rejections" :key="idx">
          {{ rej.file.name }} - {{ rej.reasons.join(', ') }}
        </p> -->
      </div>

      <div v-else class="">
        <div class="mt-6 flex justify-center">
          <the-icon icon-name="spinner" size="m" class-name="animate-spin" />
        </div>
        <p
          class="mt-4 text-center text-sm font-medium leading-[20px] text-primary"
        >
          Analyzing invoice(s)...
        </p>
      </div>
    </div>

    <div
      v-if="
        !connectionStore.connectedAccountingPlatforms.isLoading &&
        !isAccountingConnected
      "
    >
      <div class="relative mt-6">
        <div class="absolute -top-3 left-0 flex w-full justify-center">
          <div class="bg-white p-1 px-3.5 uppercase">Or</div>
        </div>
        <div class="h-1 w-full border-b"></div>
      </div>

      <div class="mt-10 flex w-full items-center justify-center gap-x-5">
        <div :class="!isAccountingConnected ? 'ml-11' : ''">
          <!-- <nuxt-link :to="routes.newBill">
            <Button
              :disabled="isUploading"
              variant="outline"
              @click="handleNewBillWithoutOCR"
              >Add a bill without attachment</Button
            >
          </nuxt-link> -->
        </div>
        <div v-if="!isAccountingConnected">
          <nuxt-link
            :to="routes.accountingConnectionsSettings"
            @click="closeBillUpload"
          >
            <Button :disabled="isUploading" variant="outline"
              >Import bills via accounting software</Button
            >
          </nuxt-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import TheIcon from '@/components/shared/the-icon.vue'
import { useBillsStore } from '@/stores/bills'
import { useConnectionsStore } from '@/stores/connections'
import { useProfileStore } from '@/stores/profile'
import { useToast } from '~/components/ui/toast'
import type { RejectedFileAndReasons } from '~/components/uploads/FileUploader.vue'
import { fileUploadRejectionReason } from '~/lib/utils'
import { setModalState } from '~/services/modal'
import { routes } from '~/utils/routes'

const { $api } = useNuxtApp()
const profileStore = useProfileStore()
const billStore = useBillsStore()
const router = useRouter()
const connectionStore = useConnectionsStore()
const isUploading = ref(false)
const { toast } = useToast()

const files = ref<File[]>([])

const orgId = computed(() => profileStore?.data?.organisation?.id)

const isAccountingConnected = computed(
  () => connectionStore.connectedAccountingPlatforms.count === 1,
)

const handleOCRUpload = async () => {
  if (files.value.length) {
    isUploading.value = true
    try {
      const response = await $api.ocr.uploads.parseInvoiceUsingOCR({
        organisation_id: orgId.value,
        invoice: files.value[0],
      })

      if (response.status === 200) {
        billStore.setOCRData({
          ...response.data,
          files: files.value,
        })
        isUploading.value = false
        billStore.setSelectedBill(null)
        billStore.setSelectedBillInvoices([])
        billStore.setSelectedVendor(null)
        closeBillUpload()
        router.push(routes.newBill)
      }
    } catch (error) {
      isUploading.value = false
      billStore.setSelectedBillInvoices(files.value)
      router.push(routes.newBill)
      closeBillUpload()
      toast({
        duration: 10000,
        title: 'Invoice details could not be extracted.',
        description:
          'Your invoice details could not be extracted at this time. Please manually enter the invoice details',
      })
    }
  }
}

const onDrop = (acceptedFiles: File[]) => {
  const newFiles = acceptedFiles.filter(
    (file) =>
      !files.value.some((existingFile) => existingFile.name === file.name),
  )
  files.value = [...newFiles, ...files.value]

  handleOCRUpload()
}

const closeBillUpload = () => {
  setModalState({ isOpen: false, type: 'newBill' })
}

// const handleNewBillWithoutOCR = () => {
//   billStore.setOCRData(null)
//   billStore.setSelectedBill(null)
//   billStore.setSelectedBillInvoices([])
//   billStore.setSelectedVendor(null)
//   closeBillUpload()
// }

// const _rejections = ref<{ file: File; reasons: string[] }[]>([])
function handleRejections(_rejections_: RejectedFileAndReasons[]) {
  const rejectionsReasons = _rejections_[0].reasons
  const uploadErrorMessages = rejectionsReasons
    .map((reason) => fileUploadRejectionReason[reason])
    .join('\n\n')
  toast({
    title: 'File rejected',
    description: `${uploadErrorMessages}`,
    variant: 'destructive',
    duration: 10000,
  })
}
</script>
